// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Units are deprecated, use the $s* variables instead */\n/* Sizes using rem. This is based on a default 16px */\n/* This is to provide better control and consistency in managing stacking components */\n/* Custom sizes */", ""]);
// Exports
exports.locals = {
	"blue50": "#eff4ff",
	"blue100": "#e0f0ff",
	"blue200": "#cee0ff",
	"blue300": "#84a6fa",
	"blue400": "#8eb9fb",
	"blue500": "#3a82fa",
	"blue600": "#2971eb",
	"blue700": "#1a5ed1",
	"blue800": "#1440aa",
	"blue900": "#0c187c",
	"purple50": "#f3e5f5",
	"purple100": "#e1bee7",
	"purple200": "#ce93d8",
	"purple300": "#ba68c8",
	"purple400": "#ab47bc",
	"purple500": "#9c27b0",
	"purple600": "#8e24aa",
	"purple700": "#7b1fa2",
	"purple800": "#6a1b9a",
	"purple900": "#4a148c",
	"deepPurple50": "#ede7f6",
	"deepPurple400": "#7e57c2",
	"amber400": "#ffca28",
	"cyan400": "#26c6da",
	"deepOrange400": "#ff7043",
	"lightGreen400": "#8bc34a",
	"pink400": "#ec407a",
	"teal400": "#26a69a",
	"lightBlue50": "#f0faff",
	"lightBlue100": "#d1f0ff",
	"lightBlue200": "#c2e9ff",
	"lightBlue300": "#a0deff",
	"lightBlue400": "#78d0ff",
	"lightBlue500": "#49c0ff",
	"lightBlue600": "#00a6ff",
	"lightBlue700": "#00a6da",
	"lightBlue800": "#00539b",
	"lightBlue900": "#003b6f",
	"green50": "#eafaea",
	"green100": "#d5f5d6",
	"green200": "#c7f2c8",
	"green300": "#b8efba",
	"green400": "#aaebac",
	"green500": "#8ee591",
	"green600": "#43a047",
	"green700": "#3cb53e",
	"green800": "#378a39",
	"green900": "#0c630f",
	"red50": "#ffebeb",
	"red100": "#ffdcdc",
	"red200": "#ffbdbd",
	"red300": "#ff9b9b",
	"red400": "#ff8181",
	"red500": "#ff5858",
	"red600": "#f81616",
	"red700": "#cc0000",
	"red800": "#990000",
	"red900": "#6a0000",
	"orange50": "#fff4eb",
	"orange100": "#ffe9d7",
	"orange200": "#ffd2af",
	"orange300": "#ffcf99",
	"orange400": "#ffa257",
	"orange500": "#ff953a",
	"orange600": "#ff8800",
	"orange700": "#df7700",
	"orange800": "#804100",
	"orange900": "#653000",
	"grey50": "#f6f6f6",
	"grey100": "#ebebeb",
	"grey200": "#d3d4d9",
	"grey300": "#c1c2c7",
	"grey400": "#adaeb3",
	"grey500": "#919297",
	"grey600": "#5d5e61",
	"grey700": "#333333",
	"grey800": "#232323",
	"grey900": "#141414",
	"grey": "#1c1c1c",
	"transparent50": "rgba(255, 255, 255, 0.2)",
	"transparent100": "rgba(255, 255, 255, 0.1215686275)",
	"transparent200": "rgba(255, 255, 255, 0.0784313725)",
	"transparent300": "rgba(0, 0, 0, 0.2)",
	"transparent400": "rgba(0, 0, 0, 0.0784313725)",
	"black": "#000",
	"white": "#fff"
};
module.exports = exports;
