// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200);"]);
// Module
exports.push([module.id, "/* Units are deprecated, use the $s* variables instead */\n/* Sizes using rem. This is based on a default 16px */\n/* This is to provide better control and consistency in managing stacking components */\n/* Custom sizes */\n* {\n  box-sizing: border-box;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n  font-family: inter, sans-serif;\n  height: 100vh;\n  line-height: 1.6;\n  min-width: 675px;\n  background-color: var(--palette-background-default);\n  color: #333333;\n}\n\nh1,\nh2,\nh4 {\n  margin: 0;\n  font-family: inter, sans-serif;\n  font-weight: normal;\n}\n\nh2 {\n  margin-bottom: 8px;\n}\n\nh3 {\n  font-family: inter, sans-serif;\n  margin: 0;\n}\n\nh4 {\n  font-weight: 600;\n}\n\nsection,\npage {\n  display: block;\n}\n\np {\n  margin-bottom: 16px;\n}\n\n#root {\n  height: 100%;\n}\n\nhtml {\n  font-size: 16px;\n}\n\n.campaigndesigner-snackbar-container {\n  margin-left: 70px;\n  z-index: 4000 !important;\n}\n.campaigndesigner-snackbar-container .notistack-MuiContent {\n  border-radius: var(--shape-borderRadius);\n}", ""]);
// Exports
module.exports = exports;
